<template>

    <b-col cols="12">
      <b-row>
        
        <b-col cols="4" />
        
        <b-col cols="4">
          <b-card title="Forgot Password">
          <b-form-group
              label="Email"
              
            >
              <b-form-input
                v-model="form.email"
                
              ></b-form-input>
          </b-form-group>

          <!-- <b-form-group
              label="OTP"
              description="Input OTP"
            >
              <b-form-input type="number" v-model="submit.otp"/>
          </b-form-group> -->

          <b-btn @click="forgetPass" variant="success" class="mb-1; mr-1">
            Submit
          </b-btn>
          <!-- <b-btn @click="resend" class="mb-1; mr-1">
            Resend
          </b-btn>
          <b-btn @click="verify" class="mb-1; mr-1">
            Verify
          </b-btn> -->
           </b-card>
        </b-col>
         
        <b-col cols="4" />
      </b-row>
     
    </b-col>
 
</template>

<script>
import { mapActions } from 'vuex';
import axios from '@/axios';

export default {
  data() {
    return {
      form: {
        email: '',
      },
      submit:{otp:''}
    }
  },
  methods: {
    forgetPass() {
      this.form.email = this.form.email.toLowerCase()
      axios.post('auth/forgot-password', this.form).then((response) => {
        this.$bvToast.toast(`You have request a forget password ${this.form.email}`, {
          title: "Success",
          variant: "success",
          solid: true,
        });
      }).catch((e) => {
        console.log({e})
        this.$bvToast.toast(`${e.response.data.message}`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
      })
    },
  }
}
</script>
